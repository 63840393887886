import { ComponentConfig } from "@/pages/employer-page/model/employer-page-types.ts";
import { CommonProps } from "@/shared/libs/dynamic-components-render.tsx";
import { INewsItem } from "@/pages/info-center-all-news-page/model";

export const InfoCenterOneNewsPageConfig = ({
                                              newsItem,
                                              recommendedNews
                                            }: {
  newsItem?: INewsItem;
  recommendedNews: INewsItem[];
}): ComponentConfig<CommonProps>[] => [
  {
    component: "OneNewsSection",
    props: {
      newsItem: newsItem
    }
  },
  {
    component: "RecommendedNews",
    props: {
      recommendedNews
    }
  },
  {
    component: "AllNewsBtn",
    props: {}
  }
];
