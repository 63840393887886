import { CommonProps, renderComponent } from "@/shared/libs/dynamic-components-render.tsx";
import { Fragment, useEffect, useState } from "react";
import { getInfoCenterOneNewsConfig } from "@/pages/info-center-news-page/api/page-config.ts";
import { ComponentConfig } from "@/pages/employer-page/model/employer-page-types.ts";
import { useParams } from "react-router";
import { getNewsItem, getRecommendedNews } from "@/pages/info-center-news-page/api/news.ts";

const InfoCenterNewsPage = () => {
  const { id } = useParams();
  const [pageConfig, setPageConfig] = useState<ComponentConfig<CommonProps>[]>();

  useEffect(() => {
    const currentNewsItem = getNewsItem(id);
    const recommendedNews = getRecommendedNews();
    const currentPageConfig = getInfoCenterOneNewsConfig({ newsItem: currentNewsItem, recommendedNews });
    if (currentPageConfig) setPageConfig(currentPageConfig);
  }, []);

  if (!pageConfig) return null;

  return pageConfig.map((item, index) => <Fragment key={index}>{renderComponent(item)}</Fragment>);
};

export default InfoCenterNewsPage;
