import React from 'react';
import { CardWithFloatBtn } from '@/widgets/card-with-float-btn';
import { ComponentConfig } from './broker-page-types';
import { StaticCard } from '@/widgets/static-card';


export const componentMap = {
    section: (props: React.HTMLProps<HTMLElement>) => <section {...props} />,
    CardWithFloatBtn,
    StaticCard,
};

export const renderComponent = <T,> ({ component, props, children }: ComponentConfig<T>) => {
    const Component = componentMap[component] as React.ComponentType<T>;

    return (
        <Component {...props}>
            {children &&
                children.map((childConfig, index) => (
                    <React.Fragment key={index}>{renderComponent(childConfig)}</React.Fragment>
                ))}
        </Component>
    );
};