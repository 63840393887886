import React from "react";
import { EmployerTask } from "@/enteties/employer-task";
import { EmployerTitle } from "@/enteties/employer-title";
import { ProfileCard } from "@/enteties/profile-card";
import { Navbar } from "@/features/navbar";
import { SectionList } from "@/features/section-list";
import { VicePresidentsList } from "@/features/vice-presidents-list";
import { Video } from "@/features/video";
import { CardWithFloatBtn } from "@/widgets/card-with-float-btn";
import { TransitionCard } from "@/widgets/transition-card";
import { ComponentConfig } from "@/pages/employer-page/model/employer-page-types";
import { CategoryFilter } from "@/pages/info-center-all-news-page/ui/components/category-filter";
import { ViewSwitchSection } from "@/pages/info-center-all-news-page/ui/components/view-switch-section";
import NewsContainer from "@/pages/info-center-all-news-page/ui/components/news-container.tsx";
import RecommendedNews from "@/pages/info-center-news-page/ui/components/recommended-news.tsx";
import OneNewsSection from "@/pages/info-center-news-page/ui/components/news-section.tsx";
import AllNewsBtn from "@/pages/info-center-news-page/ui/components/all-news-btn.tsx";
import { ContactsMenuItemList } from "@/features/contacts-menu-items-list/ui";
import { ContactsContentList } from "@/features/contacts-content";

export interface CommonProps {
  [key: string]: unknown;
}

export const componentMap: CommonProps = {
  section: (props: React.HTMLProps<HTMLElement>) => <section {...props} />,
  CardWithFloatBtn,
  Navbar,
  Video,
  SectionList,
  EmployerTitle,
  ProfileCard,
  VicePresidentsList,
  EmployerTask,
  TransitionCard,
  CategoryFilter,
  ViewSwitchSection,
  NewsContainer,
  OneNewsSection,
  RecommendedNews,
  AllNewsBtn,
  ContactsMenuItemList,
  ContactsContentList
};

export const renderComponent = <T, >({ component, props, children }: ComponentConfig<T>) => {
  const Component = componentMap[component] as React.ComponentType<T>;

  return (
    <Component {...props}>
      {children &&
        children.map((childConfig, index) => (
          <React.Fragment key={index}>{renderComponent(childConfig)}</React.Fragment>
        ))}
    </Component>
  );
};
